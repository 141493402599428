import React, { useEffect, useState, useRef } from 'react';
import {
    Layout,
    Typography,
    List,
    Button,
    Divider,
    Row,
    Col,
    notification,
    Skeleton,
} from 'antd';
import { RightOutlined, ShoppingOutlined } from '@ant-design/icons';
import moment from 'moment';
import HeaderTop from '../../../components/General/HeaderTop';
import Footer from '../../../components/Client/Footer';
import { getAccessTokenApi } from '../../../api/auth';
import { getCompanyIdApi, getCompanyImageApi } from '../../../api/company';
import { getAllOrdersFiltersApi } from '../../../api/order';
import useAuth from '../../../hooks/useAuth';
import Logo from '../../../assets/img/png/logoControlB.png';
import { userLogOut } from '../../../utils/general';

import './ListOrdersActive.scss';

const ListOrdersActive = ({ history }) => {
    const isMounted = useRef(true);
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const { user } = useAuth();
    const companyImage = getCompanyImageApi();
    const token = getAccessTokenApi();

    const { Content } = Layout;

    useEffect(() => {
        getActiveOrder();
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    const getActiveOrder = () => {
        const companyId = getCompanyIdApi();
        const clientId =
            user[
                'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
            ];
        const filters = `?CompanyId=${companyId}&ClientId=${clientId}&Status=1&Status=2&Status=3&Status=4&Status=5`;
        getAllOrdersFiltersApi(token, filters)
            .then((data) => {
                if (isMounted.current) {
                    if (data?.statusCode === 200) {
                        setOrders(data.result);
                    } else {
                        notification['error']({
                            message:
                                'Ocurrio un error al cargar la información, intentelo mas tarde.',
                        });
                    }
                }

                setLoading(false);
            })
            .catch((err) => {});
    };

    // const handleSelectCard = (id) => {
    //     setCardSelect(id);
    //     // setStripePaymentMethod(id);
    // };

    return (
        <>
            <Layout className='credit-cards'>
                <Content className='credit-cards__content'>
                    <HeaderTop
                        logoCompany
                        imageCompany={
                            companyImage === null ? Logo : companyImage
                        }
                        title={'Órdenes Activas'}
                    />
                    <div className='credit-cards__container'>
                        <ListOrders
                            orders={orders}
                            loading={loading}
                            history={history}
                        />

                        <Divider></Divider>
                    </div>
                </Content>
            </Layout>
            <Footer basket={false} showOrder showHome isOrder />
        </>
    );
};

function ListOrders(props) {
    const { orders, loading, history } = props;

    const listData = [];
    for (let i = 0; i < 8; i++) {
        listData.push({
            href: 'https://ant.design',
            title: `ant design part ${i}`,
        });
    }

    const handleGoOrder = (id) => {
        history.push(`/statusOrder/${id}`);
    };

    return (
        <>
            {!loading && (
                <List
                    itemLayout='horizontal'
                    dataSource={orders}
                    renderItem={(order) => (
                        <List.Item
                            onClick={() => handleGoOrder(order.orderId)}
                            actions={[
                                <Button type='text'>
                                    <RightOutlined
                                        style={{ color: '#b5b3b3' }}
                                    />
                                </Button>,
                            ]}
                        >
                            <List.Item.Meta
                                // avatar={<AvatarCard order={order} />}
                                title={<TitleCard order={order} />}
                                description={<StatusOrder order={order} />}
                            />
                        </List.Item>
                    )}
                />
            )}

            {loading && (
                <List
                    itemLayout='horizontal'
                    dataSource={listData}
                    renderItem={(item) => (
                        <List.Item>
                            <Skeleton
                                loading={loading}
                                active
                                paragraph={false}
                                title
                                avatar={{ shape: 'square' }}
                            ></Skeleton>
                        </List.Item>
                    )}
                />
            )}
        </>
    );
}

function TitleCard(props) {
    const { order } = props;
    const { Text, Title } = Typography;
    return (
        <Row>
            <Col>
                <Title level={4}>
                    <ShoppingOutlined
                        style={{
                            fontSize: '20px',
                            color: '#08c',
                            marginRight: '15px',
                        }}
                    />
                    {` Orden No: ${order.orderNum}`}
                </Title>
                <Text type='secondary'>
                    {moment(order.orderDateTime).format('DD/MM/YYYY')}
                    {' - '}
                    {moment(order.orderDateTime).format('h:mm A')}
                </Text>
            </Col>
        </Row>
    );
}

function StatusOrder(props) {
    const { order } = props;
    const { Text } = Typography;

    let status = '';

    switch (order.status) {
        case 1:
            status = 'Nueva';

            break;

        case 2:
            status = 'Aceptada';

            break;

        case 4:
            if (order.orderType === 1) {
                status = 'Lista';
            } else {
                status = 'En camino';
            }

            break;

        case 5:
            status = 'Entregada';

            break;

        default:
            break;
    }
    return (
        <Row>
            <Col>
                <Text type='secondary'>
                    Estatus: {status}, para{' '}
                    {order.orderTypeText === 'ADomicilio'
                        ? 'Domicilio'
                        : order.orderTypeText}
                </Text>
            </Col>
        </Row>
    );
}

export default ListOrdersActive;
