import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Menu, Row, Col, Button, Badge, Drawer } from 'antd';
import {
    ShoppingCartOutlined,
    HomeOutlined,
    HomeFilled,
    LoginOutlined,
    SolutionOutlined,
    ContainerFilled,
    SyncOutlined,
    UserOutlined,
    FormOutlined,
    ContainerOutlined,
    CustomerServiceOutlined,
    WalletOutlined,
} from '@ant-design/icons';
import { logout } from '../../../api/auth';
import Modal from '../../../components/General/Modal';
import BasketForm from '../../../components/Client/BasketForm';

import './Footer.scss';

const Footer = (props) => {
    const {
        showOrder,
        basket,
        isHome,
        order,
        countItems,
        showHome,
        isOrder,
        disabledHome,
        disabledOrder,
        activeOrderID,
    } = props;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [counterItem, setCounterItem] = useState(countItems);
    let history = useHistory();
    const [visible, setVisible] = useState(false);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    function logoutUser() {
        logout();
        window.location.reload();
    }

    function goHome() {
        if (!disabledHome) {
            history.push('/');
        }
    }

    function goStatusOrder() {
        if (!disabledOrder) {
            history.push('/orders/active');
            // history.push({
            //     pathname: '/statusOrder',
            //     state: {
            //         orderId: activeOrderID,
            //     },
            // });
        }
    }

    const menu = (
        <Menu>
            <Menu.Item icon={<UserOutlined />}>
                <Link to={'/perfil'}>Mi Perfil</Link>
            </Menu.Item>

            <Menu.Item icon={<ContainerOutlined />}>
                <Link to={'/orders/history'}>Mis Órdenes</Link>
            </Menu.Item>

            <Menu.Item icon={<WalletOutlined />}>
                <Link to={'/payment'}>Pagos</Link>
            </Menu.Item>

            <Menu.Item icon={<SyncOutlined />}>
                <Link to={'/updates'}>Actualizaciones</Link>
            </Menu.Item>

            <Menu.Item icon={<FormOutlined />}>
                <Link to={'/policy'}>Términos y Condiciones</Link>
            </Menu.Item>

            <Menu.Item icon={<CustomerServiceOutlined />}>
                <Link to={'/help'}>Ayuda</Link>
            </Menu.Item>

            <Menu.Item icon={<LoginOutlined />}>
                <Button
                    type='text'
                    className='menu-footer-btn'
                    onClick={logoutUser}
                >
                    Cerrar Sesión
                </Button>
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <Row className='footer-content'>
                <Col span={8}>
                    {basket && (
                        <>
                            <Badge size='large' count={counterItem}>
                                <Button
                                    size='large'
                                    ghost
                                    onClick={() => setIsModalVisible(true)}
                                    shape='circle'
                                    icon={<ShoppingCartOutlined />}
                                />
                            </Badge>
                            <p>Canasta</p>
                        </>
                    )}
                    {showOrder && (
                        <>
                            <Badge
                                count={activeOrderID ? activeOrderID : 0}
                                className='order-badge-count'
                            >
                                <Button
                                    size='large'
                                    onClick={goStatusOrder}
                                    ghost
                                    shape='circle'
                                    className={isOrder ? 'active' : ''}
                                    icon={
                                        isOrder ? (
                                            <ContainerFilled />
                                        ) : (
                                            <SolutionOutlined />
                                        )
                                    }
                                />
                            </Badge>
                            <p>Orden</p>
                        </>
                    )}
                </Col>

                <Col span={8} className='footer-content__center'>
                    {showHome && (
                        <>
                            <Button
                                ghost
                                shape='circle'
                                icon={
                                    isHome ? <HomeFilled /> : <HomeOutlined />
                                }
                                onClick={goHome}
                                className={isHome ? 'active' : ''}
                                size='large'
                            />
                            <p>Inicio</p>
                        </>
                    )}
                </Col>

                <Col span={8} className='footer-content__right'>
                    {/* <Dropdown overlay={menu} placement="topCenter"> */}
                    <Button
                        ghost
                        size='large'
                        shape='circle'
                        icon={<UserOutlined />}
                        onClick={showDrawer}
                    />
                    {/* </Dropdown> */}
                    <p>Usuario</p>
                </Col>
            </Row>
            <Drawer
                title='Usuario'
                placement={'right'}
                closable={false}
                onClose={onClose}
                visible={visible}
            >
                {menu}
            </Drawer>
            <Modal
                isVisible={isModalVisible}
                setIsVisible={setIsModalVisible}
                title={'Orden'}
                footer={false}
                destroyOnClose
            >
                <BasketForm
                    order={order}
                    setCounterItem={setCounterItem}
                    setIsModalVisible={setIsModalVisible}
                />
            </Modal>
        </>
    );
};

export default Footer;
