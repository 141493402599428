import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import MenuCustomer from '../../../components/Client/MenuCustomer';
import SearchForm from '../../../components/Client/SearchForm';
import HeaderTop from '../../../components/General/HeaderTop';
import { getCompanyIdApi, getCompanyNameApi } from '../../../api/company';
import Client from '../Client';
import useOrder from '../../../hooks/useOrder';

import './MenuCustome.scss';

export default function Menu(props) {
    const { history } = props;
    const companyId = getCompanyIdApi();
    const companyName = getCompanyNameApi();
    const [showViewMenu, setshowViewMenu] = useState(true);
    const { orderGeneral, setOrderGeneral, reset } = useOrder();

    const [empty, setEmpty] = useState({
        ClientAddress: '',
        OrderType: 0,
        DeliveryCost: 0,
        TableNumber: '',
        InfoCompany: null,
        ScheduleCompany: [],
        DistanceKM: 0,
    });

    const {
        ClientAddress,
        OrderType,
        TableNumber,
        DeliveryCost,
        InfoCompany,
        ScheduleCompany,
        DistanceKM,
    } = history.location.state !== undefined ? history.location.state : empty;

    const [nameRestaurant, setNameRestaurant] = useState(companyName);

    useEffect(() => {
        setNameRestaurant(companyName);
        return () => {
            setEmpty({
                ClientAddress: '',
                OrderType: 0,
                TableNumber: '',
            });
            // setOrderGeneral({
            //     ...orderGeneral,
            //     OrderType: '',
            // });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyName]);

    useEffect(() => {
        setOrderGeneral({
            ...orderGeneral,
            CompanyId: companyId,
            OrderType: OrderType,
            ClientAddress: ClientAddress,
            TableNumber: TableNumber,
            delivery: DeliveryCost,
            Distance: DistanceKM,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (orderGeneral.OrderType === 0) {
        return (
            <>
                <Route path='/' component={Client} />
                <Redirect to='/' />
            </>
        );
    }

    const searchModal = () => {
        setshowViewMenu(false);
    };

    const goMenu = () => {
        setshowViewMenu(true);
    };

    const emptyBasket = () => {
        // setOrderGeneral({ ...orderGeneral, orderLinesList: [] });
        reset();
        history.push('/');
    };

    return (
        <Layout className='menu-customer'>
            <HeaderTop
                backIcon={
                    orderGeneral.orderLinesList.length === 0
                        ? showViewMenu
                            ? true
                            : false
                        : false
                }
                route={'/'}
                confirmBackIcon={emptyBasket}
                history={history}
                backIconFunction={
                    orderGeneral.orderLinesList.length > 0
                        ? showViewMenu
                            ? true
                            : false
                        : false
                }
                title={nameRestaurant}
                search={showViewMenu}
                handleSearch={searchModal}
                closed={!showViewMenu}
                handleClosed={goMenu}
            />

            {showViewMenu === true ? (
                <MenuCustomer
                    infoCompany={InfoCompany}
                    scheduleCompany={ScheduleCompany}
                    OrderType={OrderType}
                />
            ) : (
                <SearchForm
                    infoCompany={InfoCompany}
                    scheduleCompany={ScheduleCompany}
                    orderType={OrderType}
                />
            )}
        </Layout>
    );
}
